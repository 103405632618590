<template>
  <div>
    <v-container class="pt-10 mt-10">
      <!-- <Sidebar /> -->
      <HeaderPage />
      <BackComponent :title_name="$t('message.title-edit-address')" />

      <v-col class="pr-2 pb-2" cols="12">
        <FormAddress :itemDetail="formAddress" @on-submit="updateFormAddress" />

      </v-col>
    </v-container>
    <Sidebar />
  </div>
</template>

<script>
import Sidebar from "../components/developmentv2/Sidebar.vue";
import HeaderPage from '../components/developmentv2/HeaderPage.vue'
import Back from "../components/Back.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import FormAddress from "../components/FormAddress.vue";
import { mdiArrowLeft, mdiMagnify } from "@mdi/js";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "EditAddress",
  components: { Sidebar, BackComponent: Back, FormAddress, HeaderPage },
  mixins: [validationMixin],
  validations: {
    formAddress: {
      name: { required },
      postal: { required },
      prefecture: { required },
      // ward: { required },
      // street_address: { required },
      // building: { required },
      // district: { required },
      city: { required },
    },
  },
  data() {
    return {
      iconArrowLeft: mdiArrowLeft,
      iconSearch: mdiMagnify,
      isValid: true,
      isPostal: false,
      dataErr: "",
      errMsg: "",
      formAddress: {
        name: "",
        postal: "",
        prefecture: "",
        ward: "",
        street_address: "",
        building: "",
        district: "",
        city: "",
        message: {}
      },
    };
  },
  methods: {
    updateFormAddress(address) {
      // this.formAddress.message.title = this.$t("message.title-updateForm-success-message")
      // let refacPostcode = this.formAddress.postal
      // refacPostcode = refacPostcode.replace("-","")
      // this.formAddress.postal = refacPostcode
      this.$store
        .dispatch("address_module/updateFormAddress", address)
        .then((res) => {
          this.errMsg = res.message;
          if (res.status == 200) {
            this.$router.push("/address");
          }
        })
        .catch((err) => {
          this.errMsg = res.message;
          return err;
        });
    },
    async dispatchAddressDetail() {
      let id = this.$router.history.current.params.id;
      await this.$store.dispatch("address_module/fetchAddressById", id);
      let addresDetail = this.$store.state.address_module.addressDetail;
      this.formAddress.name = addresDetail.address_name;
      let formatPostalcode = addresDetail.postal_code;
      formatPostalcode = formatPostalcode.slice(0,3)+'-'+formatPostalcode.slice(3)
      this.formAddress.postal = formatPostalcode
      this.formAddress.prefecture = addresDetail.prefecture;
      this.formAddress.ward = addresDetail.ward;
      this.formAddress.street_address = addresDetail.street_address;
      this.formAddress.building = addresDetail.building;
      this.formAddress.city = addresDetail.city;
      this.formAddress.district = addresDetail.district;
      this.formAddress.id = id;
    },
    async getAddress(){
      let postalcode = this.formAddress.postal
      postalcode = postalcode.replace("-","")
      let resp = await this.$store.dispatch("address_module/getAddressByPostal", {postal: postalcode})
      if(resp.data.length > 0){
        let respaddr = resp.data[0]
        this.formAddress.prefecture = respaddr.pref
        this.formAddress.city = respaddr.city
        this.formAddress.district = respaddr.town
      }else{
        let message
        if(typeof resp.data.message === 'undefined') message = this.$t("message.err-msg-postal-code-notfound")
        else message = this.$t("message.err-msg-postal-code-api-error")

        this.formAddress.prefecture = ""
        this.formAddress.city = ""
        this.formAddress.district = ""
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: false,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            },
          })
          Toast.fire({
            icon: 'warning',
            title: message
          })
      }
    }
  },
  computed: {
  },
  created() {
    this.dispatchAddressDetail();
  },
};
</script>

<style scoped>
.required:after {
  content: " *";
  color: red;
}
</style>